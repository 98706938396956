import * as React from 'react';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useAuth } from 'common/auth/AuthHook';
import MortgageCaveatRouter from 'licence_management/mortgage_caveat/MortgageCaveatRouter';

import ProtectedRoute from '../common/auth/ProtectedRoute';
import ApplyLicencePage, { ContinueApplyLicencePage, CopyApplicationPage } from './apply/ApplyLicencePage';
import ManagementRightRouter from './management_right/ManagementRightRouter';

const CancelLicencePage = React.lazy(() => import('./cancel/CancelLicencePage'));
const CraftLicencePage = React.lazy(() => import('./craft/CraftLicencePage'));
const IncompleteTransferRequestPage = React.lazy(() => import('./licence_transfer_request/IncompleteTransferRequestPage'));
const LicenceTransferRequestPage = React.lazy(() => import('./licence_transfer_request/LicenceTransferRequestPage'));
const ModifyLicencePage = React.lazy(() => import('./modify/ModifyLicencePage'));
const LicenceReferenceDataRouter = React.lazy(() => import('./reference_data/ReferenceDataRouter'));
const LicenceSummaryPage = React.lazy(() => import('./summary/LicenceSummaryPage'));
const PaymentResultForm = React.lazy(() => import('./summary/PaymentResultForm'));
const MaintainLicenceAdminPage = React.lazy(() => import('./summary/components/admin/AdminMaintainLicencePage'));

interface Props {}

export const useApplyLicenceNavigation = () => {
  const history = useHistory();

  return {
    navigateToApplyLicence: (applicationId?: number, replace?: boolean) =>
      replace
        ? history.replace('/ui/app/licence/apply' + (applicationId ? `/${applicationId}` : ''))
        : history.push('/ui/app/licence/apply' + (applicationId ? `/${applicationId}` : '')),
  };
};

export const useCopyApplicationNavigation = () => {
  const history = useHistory();
  return {
    navigateToCopyApplication: (applicationId: number, applicationType: 'spectrum' | 'radio' = 'radio') =>
      history.push(`/ui/app/licence/apply/${applicationType}/${applicationId}/copy`),
  };
};

export const useModifyLicenceNavigation = () => {
  const history = useHistory();

  return {
    navigateToModifyLicence: () => history.push('/ui/app/licence/modify'),
  };
};

export const useAdminEditLicenceNavigation = () => {
  const history = useHistory();
  const { loggedIn } = useAuth();
  return {
    navigateToAdminEditLicence: (licenceId: number, newTab?: boolean) => {
      const url = `/ui${loggedIn ? '/app' : ''}/licence/admin-edit/${licenceId}`;
      newTab ? window.open(url, '_blank') : history.push(url);
    },
  };
};

export const useLicenceTransferRequestNavigation = () => {
  const history = useHistory();

  return {
    navigateToLicenceTransferRequest: () => history.push('/ui/app/licence/transfer'),
  };
};

export const useDeclineLicenceTransferRequestNavigation = () => {
  const history = useHistory();

  return {
    navigateToDeclineLicenceTransferRequest: () => history.push('/ui/app/licence/accept'),
  };
};

export const useWithdrawLicenceTransferRequestNavigation = () => {
  const history = useHistory();

  return {
    navigateToWithdrawLicenceTransferRequest: () => history.push('/ui/app/licence/withdraw'),
  };
};

export const useCancelLicenceNavigation = () => {
  const history = useHistory();

  return {
    navigateToCancelLicence: () => history.push('/ui/app/licence/cancel'),
  };
};

export const useAutograntLicenceNavigation = () => {
  const history = useHistory();
  return {
    navigateToViewLicence: (licenceId: number, type: 'spectrum' | 'radio' = 'radio') => history.push(`/ui/app/licence/${type}/view/${licenceId}`),
  };
};

export const useViewLicenceNavigation = () => {
  const history = useHistory();
  const { loggedIn } = useAuth();
  return {
    navigateToViewLicence: (licenceId: number, type: 'spectrum' | 'radio' = 'radio', newTab?: boolean) => {
      const url = `/ui${loggedIn ? '/app' : ''}/licence/${type}/view/${licenceId}`;
      newTab ? window.open(url, '_blank') : history.push(url);
    },
  };
};

export const useViewCaveatorNavigation = () => {
  return {
    navigateToMortgageNumber: (formId?: number) => (formId ? window.open(`/ui/form-eleven/${formId}`, '_blank') : undefined),
    navigateToCaveatNumber: (formId?: number) => (formId ? window.open(`/ui/form-twenty/${formId}`, '_blank') : undefined),
  };
};

export const useCraftLicenceNavigation = () => {
  const history = useHistory();

  return {
    navigateToCraftLicence: (licenceId: number, type: 'spectrum' | 'radio' = 'radio', newTab?: boolean) =>
      newTab ? window.open(`/ui/app/licence/${type}/craft/${licenceId}`, '_blank') : history.push(`/ui/app/licence/${type}/craft/${licenceId}`),
  };
};

const LicenceRouter: React.FC<Props> = (props) => {
  const { path } = useRouteMatch();
  const queryParams = new URLSearchParams(useLocation().search);
  if (queryParams.get('payInvoiceId')) {
    return (
      <>
        <ProtectedRoute path={`${path}/:type(radio|spectrum)/view/:payInvoiceId`} component={PaymentResultForm} />
      </>
    );
  }
  if (queryParams.get('resultId')) {
    console.log(`LicenceRoter path = ${path}/view/:resultId`);

    return (
      <>
        <ProtectedRoute path={`${path}/:type(radio|spectrum)/view/:resultId`} component={PaymentResultForm} />
      </>
    );
  }
  return (
    <>
      <Switch>
        <ProtectedRoute
          path={`${path}/apply/:applicationType(radio|spectrum)/:applicationId/copy`}
          permission={['CREATE_APPLICATION']}
          component={CopyApplicationPage}
        />
        <ProtectedRoute path={`${path}/apply/:applicationId`} permission={['CREATE_APPLICATION']} component={ContinueApplyLicencePage} />
        <ProtectedRoute path={`${path}/apply`} permission={['CREATE_APPLICATION']} component={ApplyLicencePage} />
        <ProtectedRoute path={`${path}/reference`} component={LicenceReferenceDataRouter} />
        <ProtectedRoute path={`${path}/management-rights`} component={ManagementRightRouter} />
        <ProtectedRoute path={`${path}/mortgage-caveat`} component={MortgageCaveatRouter} />
        <ProtectedRoute path={`${path}/:type(radio|spectrum)/craft/:licenceId`} component={CraftLicencePage} />
        <ProtectedRoute path={`${path}/craft/:licenceId`} component={CraftLicencePage} />
        <ProtectedRoute path={`${path}/:type(radio|spectrum)/view/:licenceId`} component={LicenceSummaryPage} />
        <ProtectedRoute path={`${path}/view/:licenceId`} component={LicenceSummaryPage} />
        <ProtectedRoute path={`${path}/admin-edit/:licenceId`} component={MaintainLicenceAdminPage} />
        <ProtectedRoute path={`${path}/modify`} permission={['CREATE_APPLICATION']} component={ModifyLicencePage} />
        <ProtectedRoute path={`${path}/cancel`} permission={['CANCEL_LICENCE']} component={CancelLicencePage} />
        <ProtectedRoute path={`${path}/transfer`} permission={['CREATE_LICENCE_TRANSFER_REQUEST']} component={LicenceTransferRequestPage} />
        <ProtectedRoute path={`${path}/accept`} permission={['DECLINE_LICENCE_TRANSFER_REQUEST']} component={IncompleteTransferRequestPage} />
        <ProtectedRoute path={`${path}/withdraw`} permission={['WITHDRAW_LICENCE_TRANSFER_REQUEST']} component={IncompleteTransferRequestPage} />
      </Switch>
    </>
  );
};

export default LicenceRouter;
